const config = {
	"gatsby": {
		"pathPrefix": "/",
		"siteUrl": "https://theme.selly.io",
		"gaTrackingId": null
	},
	"header": {
		"logo": "https://selly.io/images/selly-logo.svg",
		"title": "Selly Theme Documentation",
		"githubUrl": null,
		"helpUrl": "https://selly.io/tickets/new",
		"tweetText": "",
		"links": [
			{ "text": "", "link": "" }
		],
	},
	"sidebar": {
		"forcedNavOrder": [
			"/introduction",
			"/liquid",
			"/filters",
			"/objects",
			"/templates",
			"/examples"
		],
		"links": [
			{ "text": "", "link": "" },
		]
	},
	"siteMetadata": {
		"title": "Selly Theme Documentation",
		"description": "Documentation built with mdx. Powering learn.hasura.io ",
		"ogImage": null,
		"docsLocation": null,
		"favicon": "https://selly.io/images/apple-touch-icon-180x180.png"
	},
};

module.exports = config;